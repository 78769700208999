<template>
  <div>
    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <!-- <div class=""> -->
      <datatable
        :url="`${this.$baseurl}/admin/repayments/pending-repayments`"
        :ajax="true"
        :ajax-pagination="true"
        :exportable="true"
        :index="true"
        :data="pendingRepayments.data"
        :columns="pendingRepayments.columns"
        :loading="pendingRepayments.loading"
        :query="pendingRepayments.query"
        :actions="actions"
        :page-details="true"
        :fillable="true"
        ref="table"
        :on-click="click"
      >
        <template #header>
          <div class="pt-4">
            <search-form
              placeholder="Search users names, emails, phone numbers, bvn, etc."
              class="mb-2"
              v-model="pendingRepayments.query"
              @submit="reloadTable"
            />
            <div class="flex items-start flex-wrap xl:flex-no-wrap">
              <div class="flex flex-wrap items-center w-full">
                <span class="processor-filter-title">Filters:</span>
                <template v-for="(filter, index) in pendingRepayments.filters">
                  <span
                    v-html="filter.title"
                    :key="index"
                    class="processor-filter-item"
                    :class="{ active: currentFilter === filter }"
                    @click.prevent="toggleFilter(filter)"
                  />
                </template>
              </div>
              <div class="flex flex-wrap items-center xl:ml-auto">
                <span class="processor-filter-title">Exports:</span>
                <button
                  class="btn btn-purple-outline btn-sm mb-2 mr-2"
                  @click.prevent="$refs.table.exportAll()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export All Results</span>
                </button>
                <!-- <button
                  class="btn btn-blue-outline btn-sm mb-2"
                  @click.prevent="$refs.table.exportData()"
                >
                  <ion-icon name="cloud-download-outline" class="text-base" />
                  <span class="ml-2">Export Filtered Results</span>
                </button> -->
              </div>
            </div>
          </div>
        </template>
      </datatable>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="settleRepaymentModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">
        Confirm Settlement
      </h4>

      <div>
        <template v-if="getFormError(settlementForm)">
          <div class="alert alert-red-soft -mt-6 mb-10">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{
              getFormError(settlementForm)
            }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2">
            <div class="text-xl font-bold">
              {{
                selectedRepayment ? selectedRepayment.statement.user.name : ''
              }}
              {{
                selectedRepayment
                  ? selectedRepayment.statement.user.last_name
                  : ''
              }}
            </div>
          </div>

          <p class="text-gray-500">Total Oustanding Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
            <div class="text-xl font-bold">
              ₦{{ outstandingAmount | currency }}
            </div>
          </div>

          <p class="text-gray-500">Percentage paid</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
            <div class="text-xl font-bold">
              {{ percentageTransferred | currency }}%
            </div>
          </div>

          <p class="text-gray-500">Percentage Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
            <div class="text-xl font-bold">
              ₦{{ percentageAmount | currency }}
            </div>
          </div>
        </template>

        <form-group
          type="select"
          :options="settlementOptions"
          name="settlementStatus"
          v-model="settlementForm.data.settlementStatus.value"
          :form="settlementForm"
        >
          Settlement Status
        </form-group>

        <div class="text-center">
          <button
            @click.prevent="settleRepayment"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="percentageAmount <= 0 || clearing"
          >
            <template v-if="clearing">Settling...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="markAsUnPaidModal"
      @close="removeError"
    >
      <h4 class="text-2xl font-bold mb-16">
        Reverse Mark as Paid
      </h4>

      <div>
        <template v-if="getFormError(markAsUnPaidForm)">
          <div class="alert alert-red-soft -mt-6 mb-10">
            <span class="alert-icon">!</span>
            <span class="text-xs font-normal">{{
              getFormError(markAsUnPaidForm)
            }}</span>
          </div>
        </template>
        <template v-if="selectedRepayment">
          <p class="text-gray-500">Name</p>
          <div class="rounded p-2 mb-1">
            <div class="text-xl font-bold">
              {{
                selectedRepayment ? selectedRepayment.statement.user.name : ''
              }}
              {{
                selectedRepayment
                  ? selectedRepayment.statement.user.last_name
                  : ''
              }}
            </div>
          </div>

          <p class="text-gray-500">Amount</p>
          <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
            <div class="text-xl font-bold">
              ₦{{ outstandingAmount | currency }}
            </div>
          </div>
        </template>

        <p class="text-gray-500">Percentage charge</p>
        <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
          <div class="text-xl font-bold">
            {{ percentageTransferred | currency }}%
          </div>
        </div>

        <p class="text-gray-500">Percentage Amount</p>
        <div class="bg-blue-200 rounded p-2 text-center mb-10 h-12">
          <div class="text-xl font-bold">
            ₦{{ percentageAmount | currency }}
          </div>
        </div>

        <div class="text-center">
          <button
            @click.prevent="markAsUnPaid"
            class="button bg-blue-500 text-white hover:bg-blue-600"
            :disabled="outstandingAmount <= 0 || clearing"
          >
            <template v-if="clearing">Unmarking...</template>
            <template v-else>Confirm</template>
          </button>
        </div>
      </div>
    </modal>

    <modal
      className="w-full md:w-3/5 xl:w-4/10 pt-10"
      ref="commentModal"
      @close="removeError"
    >
      <h4 v-if="selectedAccount" class="text-sm font-bold mb-2">
        {{ selectedAccount.name }} {{ selectedAccount.last_name }} Account
        Comment
      </h4>
      <router-link
        v-if="selectedAccount"
        :to="{ name: 'staff-view', params: { staffId: selectedAccount.id } }"
        class="btn btn-blue mr-2 mb-6"
      >
        <ion-icon name="body-outline" class="text-xl"></ion-icon>
        <span class="ml-2">View Profile</span>
      </router-link>
      <div class="flex-grow mb-4">
        <form>
          <form-group
            type="textarea"
            name="comment"
            :form="creditCardComment"
            v-model="creditCardComment.data.body.value"
          >
            Comments.
          </form-group>

          <button
            type="button"
            class="btn btn-sm btn-blue ml-auto"
            :disabled="creditCardComment.loading"
            @click.prevent="makeComment"
          >
            <span v-if="creditCardComment.loading">Saving...</span>
            <span v-else>Save</span>
          </button>
        </form>
      </div>
      <div class="max-h-30 overflow-y-scroll">
        <template v-for="(comment, i) in currentcreditCardComments">
          <div :key="i" class="border border-gray-500 mb-4">
            <div class="border-b border-gray-500 px-4 py-1 text-sm font-thin">
              {{ comment.admin.name }} {{ comment.admin.last_name }} commented
              on {{ new Date(comment.created_at).format('dd M, Y') }}
            </div>
            <div class="py-4 px-4 font-normal">{{ comment.body }}</div>
          </div>
        </template>
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentFilter: null,
      repaymentStatus: false,
      downloading: false,
      track: 'clearBalance',
      searchQuery: '',
      company: null,
      loading: false,
      repayments: [],
      clearing: false,
      repyamentError: {},
      paymentMessage: 'Repayment Settlement was successful.',
      settlementStatus: '',
      settlementOptions: ['settled', 'unsettled'],
      settlementForm: this.$options.basicForm([
        { name: 'settlementStatus', rules: 'required' }
      ]),
      cardId: 0,
      actions: [
        {
          text: 'Settle Repayment',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openSettlementModal
          // disabled: (account) =>
          //   account.status === "paid" || account.status === "transferred",
        },
        {
          text: 'mark as unpaid',
          class:
            'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
          action: this.openMarkAsUnPaidModal
        }
      ],
      selectedAmount: null,
      actionError: null,
      selectedRepayment: null,
      selectedStatement: null,
      repaymentCards: this.$options.resource([]),
      pendingRepayments: this.$options.resource([], {
        query: '',
        columns: [
          {
            name: 'user',
            th: 'User',
            render: ({ statement }) => {
              if (!statement) {
                return 'N/A';
              }
              return `${statement?.user?.name} ${statement?.user?.last_name}`;
            }
          },
          {
            name: 'clearance_percentage',
            th: 'Percentage Paid',
            render: ({ clearance_percentage }) => {
              if (clearance_percentage) {
                return `<span class="text-blue-500">${clearance_percentage}</span>`;
              }
              return '<span class="text-gray-500">0%</span>';
            }
          },
          {
            name: 'total_outstanding',
            th: 'Total Outstanding',
            render: ({ statement }) => {
              if (statement?.total_outstanding) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  statement?.total_outstanding
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            }
          },
          {
            name: 'salary_day',
            th: 'Due Date',
            render: ({ statement }) => {
              if (statement?.user?.profile?.salary_day) {
                return `<span class="text-blue-500">
                  ${statement.user?.profile?.salary_day}
                </span>`;
              }
              return '<span class="text-gray-500">N/A</span>';
            }
          },
          {
            name: 'status',
            th: 'Status',
            render: ({ status }) => {
              switch (status) {
                case 'terminated':
                  return `<div class="badge badge-red-soft-outline">
                                            Terminated
                                        </div>`;
                case 'initiated':
                  return `<div class="badge badge-orange-soft-outline">
                                            Initiated
                        </div>`;
                case 'finalized':
                  return `<div class="badge badge-green-soft-outline">
                                            settled
                        </div>`;
                default:
                  return `<div class="badge badge-red-soft-outline">
                                                Default
                                            </div>`;
              }
            }
          },
          {
            className: 'w-1/10',
            name: 'comment',
            th: 'Comments',
            render: account => {
              // this.selectedAccount = account;
              return `<a href="#!"
                        class="btn btn-blue btn-sm mr-5"
                        data-click="click(${account}, ${'comments'})"
                      >
                        View
                      </a>`;
            }
          }
        ],
        filters: [
          { title: 'terminated', name: 'terminated' },
          { title: 'settled', name: 'finalized' },
          { title: 'markAsPaid', name: 'initiated' }
        ]
      }),
      creditCardComment: this.$options.basicForm(['body']),
      currentcreditCardComments: [],
      commentsLoading: false,
      selectedAccount: null,
      successMessage: '',
      markAsUnPaidForm: this.$options.basicForm([])
    };
  },
  computed: {
    outstandingAmount() {
      return this.selectedRepayment?.statement?.total_outstanding || 0;
    },
    creditLimit() {
      return (
        this.selectedRepayment?.statement?.personal_account
          ?.credit_card_limit || 0
      );
    },
    creditBalance() {
      return this.selectedRepayment?.statement?.personal_account
        ?.available_credit;
    },
    creditUsed() {
      return Math.abs(
        this.selectedRepayment?.statement?.personal_account
          ?.available_balance || 0
      );
    },
    amountSpent() {
      return this.formatAmount(
        this.selectedRepayment?.statement?.personal_account?.statement
          ?.agg_balance || 0
      );
    },
    interest() {
      return this.formatAmount(this.selectedStatement?.statement?.interest);
    },

    deferredPlan() {
      return this.formatAmount(
        this.selectedRepayment?.statement?.deferred_plan_amount
      );
    },

    defaultCharge() {
      return this.formatAmount(
        this.selectedRepayment?.statement?.fees_and_charges
      );
    },
    openingBalance() {
      return this.formatAmount(
        this.selectedRepayment?.statement?.opening_balance
      );
    },
    paystackFee() {
      let fee = this.selectedRepayment?.statement?.total_outstanding;
      if (fee > 2500) {
        fee = fee * 0.015 + 100;
        if (fee > 2000) {
          fee = 2000;
        }
      } else {
        fee = fee * 0.015;
        if (fee > 2000) {
          fee = 2000;
        }
      }
      return fee;
    },
    processingFee() {
      return this.formatAmount(this.selectedStatement?.processing_fee || 0);
    },
    totalBalance() {
      return this.selectedStatement?.statement?.total_outstanding;
    },
    total() {
      const sum = this.totalBalance + this.paystackFee;
      return sum;
    },
    canSettleRepayment() {
      return this.canDo('credpal:can_settle_repayment');
    },
    percentageTransferred() {
      return this.selectedRepayment?.clearance_percentage;
    },
    percentageAmount() {
      return this.selectedRepayment?.amount;
    }
  },
  beforeMount() {
    this.showActions();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    showActions() {
      if (!this.canSettleRepayment) {
        this.actions = [];
        return;
      }
    },
    openSettlementModal(user) {
      this.selectedRepayment = user;
      this.getAccountStatement();
      this.$refs.settleRepaymentModal.open();
    },
    openMarkAsUnPaidModal(account) {
      this.selectedRepayment = account;
      this.getAccountStatement();
      this.$refs.markAsUnPaidModal.open();
    },
    removeError() {
      this.actionError = '';
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    summaryData(key, default_value = 0) {
      const data = this.repaymentStat?.[key];
      return data ? data : default_value;
    },
    openCommentModal(user) {
      if (typeof user === 'string') {
        return;
      }
      this.selectedAccount = user;
      this.getCreditCardComments(user.statement?.account_id);
      this.$refs.commentModal.open();
    },
    click(defaultUser) {
      this.openCommentModal(defaultUser);
    },
    toggleFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null;
      } else {
        this.currentFilter = filter;
      }
      this.$refs.table.clickedFilter(this.currentFilter);
    },
    async getAccountStatement() {
      await this.$post({
        url: `${this.$baseurl}/admin/personal/cards/user-balance`,
        headers: this.headers,
        data: {
          account_id: this.selectedRepayment?.statement?.account_id
        },
        success: response => {
          this.selectedStatement = response.data.data;
        },
        error: error => {
          console.log('error', error.response);
        }
      });
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async settleRepayment() {
      if (!this.validateForm(this.settlementForm)) {
        return false;
      }
      const status = this.settlementForm.data.settlementStatus.value;
      let url;
      if (status === 'settled') {
        url = 'admin.cards.personal.settleRepayment';
      }
      if (status === 'unsettled') {
        url = 'admin.cards.personal.unSettleRepayment';
      }
      this.clearing = true;
      await this.sendRequest(url, {
        data: {
          admin_id: this.user?.id,
          ...(status === 'settled'
            ? { pending_repayment_id: this.selectedRepayment?.id }
            : ''),
          ...(status === 'unsettled'
            ? { repayment_id: this.selectedRepayment?.repayment_id }
            : '')
        },
        success: response => {
          this.$refs.settleRepaymentModal.close();
          this.$success({
            title: 'Statement Marked Successfully',
            body: response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
          this.reloadTable();
        },
        error: error => {
          this.settlementForm.error = error;
          this.$error({
            title: 'Statement Error',
            body: error.response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
        }
      });
      this.clearing = false;
      await this.$root.loadSession();
    },
    async makeComment() {
      this.creditCardComment.loading = true;

      await this.$post({
        url: `${this.$baseurl}/admin/personal/cards/credit-card-repayments/comment`,
        data: {
          accountId: this.selectedAccount?.personal_account?.id,
          body: this.creditCardComment.data.body.value
        },
        headers: this.headers,
        success: () => {
          // this.getStatementComments();
          this.creditCardComment = this.resetForm(this.creditCardComment);
          this.successMessage = 'Comment saved successfully.';
          this.getCreditCardComments(
            this.selectedAccount?.personal_account?.id
          );
          this.$refs.successModal.open();
        },
        error: error => {
          this.successMessage = '';
          this.creditCardComment.error = error;
        }
      });
      this.creditCardComment.loading = false;
    },
    async getCreditCardComments(accountId) {
      this.commentsLoading = true;

      await this.$get({
        url: `${this.$baseurl}/admin/personal/cards/credit-card-repayments/comment/${accountId}`,
        headers: this.headers,
        success: response => {
          this.currentcreditCardComments = response.data.comments;
        },
        error: error => {
          this.creditCardComment.error = error;
        }
      });
      this.commentsLoading = false;
      await this.$root.loadSession();
    },
    async markAsUnPaid() {
      this.clearing = true;
      await this.sendRequest('admin.cards.personal.markCardAsUnPaid', {
        data: {
          admin_id: this.user?.id,
          pending_repayment_id: this.selectedRepayment?.id
        },
        success: response => {
          this.$refs.markAsUnPaidModal.close();
          this.$success({
            title: 'Statement Marked Successfully',
            body: response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
          this.reloadTable();
        },
        error: error => {
          this.markAsUnPaidForm.error = error;
          this.$error({
            title: 'Statement Error',
            body: error.response?.data?.message ?? 'There was an error.',
            button: 'Okay'
          });
        }
      });
      this.clearing = false;
    }
  }
};
</script>

<style lang="sass">
.processor-select-input
  @apply bg-white border border-blue-200 duration-300 font-medium px-6 py-3 relative rounded-sm text-gray-600
  font-size: 13px
  &:hover
    @apply bg-blue-200 border-blue-200 text-blue-900
  &:focus-within
    @apply border-blue-500 text-blue-500
.processor-filter-title
  @apply font-bold mb-2 mr-2 text-sm uppercase w-full
.processor-filter-item
  @apply bg-white border cursor-pointer font-bold mb-2 mr-2 px-3 py-2 rounded-sm text-xs text-gray-600 tracking-wide uppercase
  &:hover
    @apply bg-blue-100 border-blue-500 text-blue-500
  &.active
    @apply bg-blue-500 border-blue-500 text-white
</style>
